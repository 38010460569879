var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"admin-page"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"cold5 text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"h1sdqqqa"},[_vm._v("Admin Panel")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"height":"400","fixed-header":"","headers":_vm.headers,"items":_vm.data},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.firstname) + " " + (item.lastname)))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timeForHumans")(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? 'Respondido' : 'Sin Responder')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"fluent:delete-16-regular","data-width":"24"}})]),_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.toggleStatus(item)}}},[_vm._v(" "+_vm._s(!item.status ? 'marcar como respondido' : 'marcar para responder')+" ")])]}}])})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#f3976a","outlined":"","to":{ path: '/user/signout' }}},[_vm._v(" Cerrar Sesión ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }