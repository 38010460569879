<template>
  <section class="admin-page">
    <v-container>
      <v-row>
        <v-col cols="12" class="cold5 text-center">
          <h1 class="h1sdqqqa">Admin Panel</h1>
        </v-col>

        <v-col cols="12">
          <v-data-table
              height="400"
              fixed-header
              :headers="headers"
              :items="data"
          >
            <template v-slot:item.index="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:item.email="{ item }">
              <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>

            <template v-slot:item.fullName="{ item }">
              {{ `${item.firstname} ${item.lastname}` }}
            </template>

            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | timeForHumans}}
            </template>

            <template v-slot:item.status="{ item }">
              {{ item.status ? 'Respondido' : 'Sin Responder' }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn fab x-small class="mr-2" icon color="error" @click="deleteItem(item)">
                <span class="iconify" data-icon="fluent:delete-16-regular" data-width="24"></span>
              </v-btn>
              <v-btn x-small @click="toggleStatus(item)">
                {{ !item.status ? 'marcar como respondido' : 'marcar para responder' }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" class="text-right">
          <v-btn color="#f3976a" outlined :to="{ path: '/user/signout' }">
            Cerrar Sesión
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { FbDb } from "../plugins/firebaseTS";
import { collection, onSnapshot, query, orderBy, doc, updateDoc, deleteDoc  } from "firebase/firestore";

// Manejo de fechas
import 'dayjs/locale/es';
import dayjs, {ConfigType} from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  name: "Admin",

  filters: {
    timeForHumans: (value) => {
      if (!value) return;
      return dayjs(value.toDate()).locale('es').fromNow();
    },
  },

  data () {
    return {
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        { text: 'Correo Electrónico', value: 'email', sortable: true, },
        { text: 'Nombre Completo', value: 'fullName', sortable: true, },
        { text: 'Description', value: 'description', sortable: true, },
        { text: 'Creado el', value: 'createdAt', sortable: true, },
        { text: 'Estado', value: 'status', sortable: false, },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'end'},
      ],
      data: [],
    }
  },

  created () {
    const colRef = collection(FbDb, 'JoinClub');
    const ob = orderBy('email');
    const qc = query(colRef, ob);


    // Observando formulario de contacto
    onSnapshot(qc, (snapshot) => {
      const data = [];
      if(snapshot.docs.length > 0) {
        snapshot.forEach(async (item) => {
          const _item = {
            ...item.data(),
            id: item.id
          }

          data.push(_item);
        })

        this.data = data
      }
    })
  },

  methods: {
    async toggleStatus (item) {
      const colRes = await collection(FbDb, 'JoinClub');
      try {
        const docRes = await doc(colRes, item.id)

        const status = !item.status;

        await updateDoc(docRes, { status });
      } catch (e) {
        // console.log(e);
        return;
      }
    },
    async deleteItem (item) {
      const colRes = await collection(FbDb, 'JoinClub');
      try {
        const docRes = await doc(colRes, item.id)

        await deleteDoc(docRes);
      } catch (e) {
        // console.log(e);
        return;
      }
    }
  }
}
</script>

<style scoped>
.h1sdqqqa {
  color: #f3976a;
}

.cold5 {
  margin-top: 20px;
}

</style>